<template>
  <card class="project-game-instance-card">
    <div class="row">

      <div class="col-12">
        <h1>{{name}}</h1>
      </div>

      <div class="col-md-6">
        <card>
          <vue-markdown :source="descriptionFilled"></vue-markdown>
        </card>
      </div>

      <div class="col-md-6">
        <card>
          <vue-markdown :source="details"></vue-markdown>
        </card>
      </div>

    </div>
  </card>
</template>

<script>
import Mustache from "mustache"
import VueMarkdown from 'vue-markdown'

export default {
  name: "InstanceTitle",
  components: {
    VueMarkdown
  },
  props: {
    name: {
      type: [String]
    },
    description: {
      type: [String]
    },
    descriptionTags: {
      type: [Array]
    },
    details: {
      type: [String]
    }
  },
  computed: {
    descriptionFilled: function() {
      const params = {}
      if (this.descriptionTags  && this.descriptionTags.length > 0) {
        this.descriptionTags.forEach(i => {
          params[i.tag] = i.value
        })
      }

      return Mustache.render(this.description, params)
    }
  }
}
</script>

<style scoped>
</style>
