
import Vue from 'vue';
import Router from 'vue-router';

import auth from './../pbg/auth'
import GameView from "@/pages/ProjectGame/GameView"
import GameReport from "@/pages/ProjectGame/GameReport"
import LearningSolution from './../pages/ProjectGame/LearningSolution.vue'
import Layout from "../pages/ProjectGame/Layout";
import Library from "../pages/ProjectGame/Library";
import Login from "../pages/ProjectGame/Login.vue";
import Profile from "../pages/ProjectGame/Profile.vue";
import Forgot from "../pages/ProjectGame/Forgot";
import Reset from "../pages/ProjectGame/Reset";
import Register from "../pages/ProjectGame/Register.vue";
import NotFound from "../pages/ProjectGame/NotFound.vue";

Vue.use(Router);

function requireAuth (to, from, next) {
  if (!auth.loggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '',
          component: Library
        },
        {
          path: '/instance/:id',
          component: LearningSolution,
          beforeEnter: requireAuth
        },
        {
          path: '/instance/:id/playgame/:gameId',
          component: GameView,
          name: 'game',
          beforeEnter: requireAuth
        },
        {
          path: '/instance/:id/report/:gameId/:reportIndex',
          name: 'report',
          component: GameReport,
          beforeEnter: requireAuth
        },
        {
          path: '/profile',
          name: 'profile',
          component: Profile,
          beforeEnter: requireAuth
        }
      ]
    },
    { path: '/forgot', component: Forgot },
    { path: '/reset', component: Reset },
    { path: '/register', component: Register },
    { path: '/login', component: Login },
    { path: '/logout', beforeEnter (to, from, next) {
        auth.logout()
        next('/')
      }
    },
    { path: '*', component: NotFound }
  ],
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});
