<template>
  <card class="project-game-instance-card">
    <div class="row">
      <div class="col-12">
        <h3>Page statistics</h3>
      </div>
      <div class="col-12">
        <el-table style="width: 100%" :data="visitors">
          <el-table-column min-width="100" label="Nick" prop="nickName"></el-table-column>
          <el-table-column min-width="200" label="Last time viewed this page" prop="time"></el-table-column>
        </el-table>
      </div>
    </div>
  </card>
</template>

<script>
import {Table, TableColumn} from "element-ui";

export default {
  name: "InstanceVisitors",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    visitors: {
      type: [Array]
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
