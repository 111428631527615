var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('notifications'),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[(!_vm.showCreationForm)?_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Registration Complete")])]),_c('div',[_vm._v(" Account activation email has been sent to "+_vm._s(_vm.email)+". ")])]):_vm._e(),(_vm.showCreationForm)?_c('card',{attrs:{"footer-classes":"text-right"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Register Form")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"","label":"First Name","maxlength":32,"error":errors[0]},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"","label":"Last Name","maxlength":32,"error":errors[0]},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"nick","rules":{required: true, min:3, max: 24, nickIsFree: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"","label":"Nick name","maxlength":24,"error":errors[0]},model:{value:(_vm.nick),callback:function ($$v) {_vm.nick=$$v},expression:"nick"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"email","label":"Email address","maxlength":50,"error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|confirmed:confirmation|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"password","autocomplete":"new-password","label":"Password","maxlength":64,"error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","vid":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"password","label":"Confirm Password","error":errors[0]},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})]}}],null,true)}),_c('div',{staticClass:"category form-category"},[_vm._v("* Required fields")])],1),_c('template',{staticClass:"text-right",slot:"footer"},[_c('base-checkbox',{staticClass:"pull-left",attrs:{"name":"subscribe"},model:{value:(_vm.subscribe),callback:function ($$v) {_vm.subscribe=$$v},expression:"subscribe"}},[_vm._v(" Accept "),_c('a',{attrs:{"href":_vm.gdprUrl,"target":"_blank"}},[_vm._v("terms and conditions")])]),_c('base-button',{attrs:{"disabled":!_vm.subscribe,"native-type":"submit","type":"primary"}},[_vm._v("Register")])],1)],2):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }