<template>
  <base-nav type="success" class="navbar-expand-sm top-navbar sticky-top" menu-classes="justify-content-end">
    <div slot="brand">
      <router-link class="navbar-brand" to="/">
        ProjectBusinessGame
      </router-link>

      <span v-if="this.instanceLevel">/</span>

      <router-link class="navbar-brand" v-if="this.instanceLevel" :to="'/instance/' + this.instanceLevel">
        {{instanceLevelText}}
      </router-link>

      <span v-if="this.detailLevel">/</span>

      {{detailLevel}}
    </div>

    <div>
      <base-switch onText="Light" offText="Dark" v-model="darkMode" @input="toggleMode" style="margin-bottom:0"></base-switch>

      <div style="display: inline-block">
        <base-dropdown
          menu-on-right=""
          tag="div"
          title-tag="a"
          class="nav-item"
          title-classes="nav-link"
          menu-classes="dropdown-navbar"
        >
          <template slot="title">
            <span>{{user.nickName}} <b class="caret" style="position: absolute; left: initial; margin-left:5px; initial; top:55%"></b></span>
          </template>
          <li class="nav-link">
            <router-link  v-if="user" class="nav-item dropdown-item" to="/profile">
              Profile
            </router-link>
          </li>
          <div class="dropdown-divider"></div>
          <li class="nav-link">
            <router-link class="nav-item dropdown-item" to="/logout" v-on:click.native.prevent="$emit('logout')">
              Sign out
            </router-link>
          </li>
        </base-dropdown>
      </div>
    </div>
  </base-nav>
</template>

<script>
import nav from "@/pbg/nav";
import utils from "@/pbg/utils";
import {BaseNav} from 'src/components'
import {BaseSwitch} from 'src/components';

function updateBreadCrumbs(currentRoute) {
  this.instanceLevel = ""
  this.detailLevel = ""

  if (!currentRoute) {
    currentRoute = this.$router.currentRoute
  }

  const path = currentRoute.path;
  let instanceStart = path.indexOf("instance/")
  if (instanceStart !== -1) {
    instanceStart += 9
    const instanceEnd = path.substr(instanceStart).indexOf("/")
    this.instanceLevel = instanceEnd !== -1 ? path.substr(instanceStart, instanceEnd): path.substr(instanceStart)
    this.instanceLevelText = nav.instanceNames[this.instanceLevel] || this.instanceLevel
  }

  let gameStart = path.indexOf("playgame/")
  if (gameStart !== -1) {
    const gameId = path.substr(gameStart+9)
    const gameName = nav.gameNames[gameId] || "game"
    this.detailLevel = "Playing " + gameName
  }

  let reportStart = path.indexOf("report/")
  if (reportStart !== -1) {
    const reportId = path.substr(reportStart+7)
    const reportName = nav.reportNames[reportId] || ""
    this.detailLevel = "Game report " + reportName
  }

  let profileStart = path.indexOf("/profile")
  if (profileStart !== -1) {
    this.detailLevel = "Edit profile"
  }
}


export default {
  name: "Navbar",
  components: {
    BaseNav,
    BaseSwitch
  },
  data() {
    return {
      darkMode: true,
      windowLocation: window.location.origin,
      instanceLevel: "",
      instanceLevelText: "",
      detailLevel: "",
    }
  },
  props: {
    user: {
      type: [Object]
    }
  },
  methods: {
    toggleMode(type) {
      utils.setDarkMode(type)
    }
  },
  async mounted() {
    nav.emitter.on(nav.INSTANCE_NAME_CHANGE, updateBreadCrumbs.bind(this));
    updateBreadCrumbs.call(this)

    this.darkMode = utils.getDarkMode()
    utils.setDarkMode(this.darkMode)
  },
  watch:{
    $route (to, from){
      updateBreadCrumbs.call(this, to)
    }
  }
}
</script>

<style scoped>
.top-navbar {
  top: 0;
}
</style>
