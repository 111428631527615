
import EventEmitter from "eventemitter3";
import utils from "./utils"
import axios from 'axios'

const auth = {};

auth.emitter = new EventEmitter();
auth.USER_DATA_CHANGE = "USER_DATA_CHANGE";

auth.register = function(firstName, lastName, nickName, email, password, callback) {
  registerRequest(firstName, lastName, nickName, email, password, result => {
    if (callback) {
      callback(result)
    }
    this.onChange(result.created)
  })
};

auth.login = function(email, pass, callback) {
  if (localStorage.jwt) {
      if (callback) {
        callback({ authenticated: true })
      }
      this.onChange(true)
      return
  }

  loginRequest(email, pass, result => {
      if (callback) {
        callback(result)
      }
      this.onChange(result.authenticated)
  })
};

auth.getToken = function () {
  return localStorage.jwt
};

auth.logout = function (callback) {
    delete localStorage.jwt
    if (callback) {
      callback()
    }
    this.onChange(false)

    auth.emitter.emit(auth.USER_DATA_CHANGE)
};

auth.loggedIn = function () {
  return !!localStorage.jwt
};

auth.onChange = function() {
};

auth.getUser = function() {
  if (this.loggedIn()) {
    const data = localStorage.getItem('userData');
    return data ? JSON.parse(data) : null;
  }
  return null;
};

auth.forgotPassword = function(email, callback) {
  forgotRequest(email, result => {
    if (callback) {
      callback(result)
    }
  })
};

auth.reset = function(code, password, passwordConfirmation, callback) {
  resetRequest(code, password, passwordConfirmation, result => {
    if (callback) {
      callback(result)
    }
  })
};

auth.update = function(email, firstName, lastName, nickName, password, callback) {
  updateRequest(email, firstName, lastName, nickName, password, result => {
    if (callback) {
      callback(result)
    }
    this.onChange(result.updated)
  })
};

auth.validateNick = async function(newNick) {
  try {
    const authParams = auth.loggedIn() ? {
      headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}`}
    } : null;

    const response = await axios.get(utils.getApiUrl('portal-api/pbg-plugin/nickReserved/' + encodeURIComponent(newNick)), authParams);
    return !response.data.reserved;
  }
  catch(err) {
    return false;
  }
}

function saveUserToLocalStorage(res) {
  const {jwt, user} = res.data
  localStorage.setItem('jwt', jwt)
  localStorage.setItem('userData', JSON.stringify(user))
  localStorage.setItem('lastEmail', user.email)

  auth.emitter.emit(auth.USER_DATA_CHANGE)
}

async function loginRequest(email, pass, callback) {
    try {
      const res = await axios.post(utils.getApiUrl("portal-api/auth/local"), {
          identifier: email,
          password: pass
      })

      saveUserToLocalStorage(res)
      callback({ authenticated: true })
    }
    catch (err) {
      let errorMessage = "Error " + err.response.status;
      try {
        errorMessage = err.response.data.message[0].messages[0].message;
      }
      catch (errMesEx) {
        // no-op
      }
      callback({ authenticated: false, errorMessage: errorMessage })
    }
}

async function registerRequest(firstName, lastName, nickName, email, password, callback) {
  try {
    const username = email;
    const params = { username, firstName, lastName, nickName, email, password };
    const res = await axios.post(utils.getApiUrl("portal-api/auth/local/register"), params)

    if (res.data.jwt) {
      saveUserToLocalStorage(res)
    }
    callback({ created: true, loggedIn: !!res.data.jwt })
  }
  catch (err) {
    let errorMessage = "Error " + err.response.status;
    try {
      errorMessage = err.response.data.message[0].messages[0].message;
    }
    catch (errMesEx) {
      // no-op
    }
    callback({ created: false, errorMessage: errorMessage })
  }
}

async function forgotRequest(email, callback) {
  try {
    await axios.post(utils.getApiUrl("portal-api/auth/forgot-password"), {email})
    callback({ sent: true })
  }
  catch (err) {
    let errorMessage = "Error " + err.response.status;
    try {
      errorMessage = err.response.data.message[0].messages[0].message;
    }
    catch (errMesEx) {
      // no-op
    }
    callback({ errorMessage: errorMessage })
  }
}

async function resetRequest(code, password, passwordConfirmation, callback) {
  try {
    const res = await axios.post(utils.getApiUrl("portal-api/auth/reset-password"), {code, password, passwordConfirmation})

    saveUserToLocalStorage(res)
    callback({ sent: true })
  }
  catch (err) {
    let errorMessage = "Error " + err.response.status;
    try {
      errorMessage = err.response.data.message[0].messages[0].message;
    }
    catch (errMesEx) {
      // no-op
    }
    callback({ errorMessage: errorMessage })
  }
}

async function updateRequest(email, firstName, lastName, nickName, password, callback) {
  try {
    const params = {};
    if (password) {
      params.username = email;
      params.password = password;
    }
    params.firstName = firstName;
    params.lastName = lastName;
    params.nickName = nickName;

    const res = await axios.post(utils.getApiUrl("portal-api/pbg-plugin/updateUser"), params, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      }
    })

    if (res.data && res.data.id) {
      localStorage.setItem('userData', JSON.stringify(res.data))
      auth.emitter.emit(auth.USER_DATA_CHANGE)
    }
    callback({ updated: true })
  }
  catch (err) {
    let errorMessage = "Error " + err.response.status;
    console.log(err.response)
    try {
      if (typeof err.response.data.message === "string") {
        errorMessage = err.response.data.message;
      }
      else {
        errorMessage = err.response.data.message[0].messages[0].message;
      }
    }
    catch (errMesEx) {
      // no-op
    }
    callback({ updated: false, errorMessage: errorMessage })
  }
}

export default auth;
