<template>
  <div class="container">
    <modal :show.sync="modalData.visible" :gradient="!doc.body.classList.contains('white-content') ? 'dark' : ''" :modalClasses="modalData.detailedScores ? 'pbg-modal-wide' : ''">
      <h6 slot="header" id="modal-title-default">{{modalData.title}}</h6>

      <high-score-table v-if="modalData.highScores.length > 0" :highScores="modalData.highScores" :admin="modalData.detailedScores"></high-score-table>

      <game-report-table v-if="modalData.gameReports.length > 0" :gameReports="modalData.gameReports" @reportClicked="onModalReportClicked"></game-report-table>

      <div class="text-center">
        <base-button type="primary" @click="modalData.visible = false">Close</base-button>
      </div>
    </modal>

    <instance-title
      :name="title"
      :description="description"
      :descriptionTags="descriptionTags"
      :details="details"
      ></instance-title>

    <instance-game v-for="game in games" v-bind:key="game.pin"
      :name="game.name"
      :description="game.description"
      :infos="game.infos"
      :highScores="game.highScores"
      :showAllHighScores="game.highScores.length >= 3 || portalUser.gameAdmin"
      :gameReports="game.gameReports.slice(0,3)"
      :showAllReports="game.gameReports.length > 3"
      :gameRouterPath="`/instance/${$route.params.id}/playgame/${game.pin}`"
      :playDisabled="game.playDisabled"
      :gameAdmin="portalUser.gameAdmin"
      :gameId="game.pin"
      :instanceId="$route.params.id"
      :serverStatus="game.serverStatus"
      @showHighScores="showHighScores"
      @showReports="showReports"
      @gameStatusUpdated="$router.go()"
    >
    </instance-game>

    <instance-feedback v-if="feedbackUrl"
      :title="feedbackTitle"
      :description="feedbackDescription"
      :infos="surveyInfos"
      :feedbackUrl="feedbackUrl"
      :gameAdmin="portalUser.gameAdmin"
      :instanceId="$route.params.id"
      :feedbackEnabled="feedbackEnabled"
      @feedbackStatusUpdated="$router.go()"
    ></instance-feedback>

    <instance-teaching-notes v-if="portalUser && portalUser.gameAdmin && teachingNotes"
      :description="teachingNotes"
    ></instance-teaching-notes>

    <instance-visitors v-if="portalUser && portalUser.gameAdmin && visitors"
      :visitors="visitorsFormatted"
    ></instance-visitors>
  </div>
</template>


<script>
import auth from "@/pbg/auth";
import nav from "@/pbg/nav";
import utils from "@/pbg/utils";
import axios from 'axios'
import {Modal} from '@/components'
import HighScoreTable from "@/components/ProjectGame/HighScoreTable"
import InstanceTitle from "@/components/ProjectGame/InstanceTitle"
import InstanceFeedback from "@/components/ProjectGame/InstanceFeedback"
import InstanceGame from "@/components/ProjectGame/InstanceGame"
import GameReportTable from "@/components/ProjectGame/GameReportTable"
import InstanceTeachingNotes from "@/components/ProjectGame/InstanceTeachingNotes";
import InstanceVisitors from "@/components/ProjectGame/InstanceVisitors";
import {Table, TableColumn} from "element-ui"

const GAME_STATS = [
  {
    id: 0,
    title: '-',
    subTitle: 'Times played',
  },
  {
    id: 1,
    title: '-',
    subTitle: '-',
  },
  {
    id: 2,
    title: '-',
    subTitle: 'Points'
  },
  {
    id: 3,
    title: '-',
    subTitle: 'Rank'
  },
  {
    id: 4,
    title: '-',
    subTitle: 'Estimated duration',
  },
  {
    id: 5,
    title: '-',
    subTitle: 'Status'
  }
]
const SURVEY_INFO = [
  {
    id: 0,
    title: '-',
    subTitle: 'Estimated duration',
  }
]

function processLearningSolutionData(response) {
  const user = auth.getUser()
  if (!user || !response || !response.instance || !response.server ) {
    this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: "No data - make sure your address is correct."})
    return;
  }

  const instanceData = response.instance
  const data = instanceData.baseSolution

  this.title = data.title
  nav.setInstanceName(this.$route.params.id, data.title)

  this.description = data.description
  this.descriptionTags = instanceData.descriptionTags
  this.details = instanceData.details || data.details
  this.games = []

  this.portalUser = user

  this.feedbackTitle = data.feedbackTitle || this.feedbackTitle
  this.feedbackDescription = data.feedbackDescription || this.feedbackDescription
  this.feedbackUrl = getFeedbackUrl.call(this, data.feedbackUrl)
  this.feedbackDuration = data.feedbackDuration
  this.feedbackEnabled = instanceData.feedbackEnabled
  this.teachingNotes = instanceData.teachingNotes || data.teachingNotes || ""
  this.visitors = response.visitors

  if (this.feedbackUrl) {
    this.surveyInfos = JSON.parse(JSON.stringify(SURVEY_INFO))
    setDuration(this.surveyInfos[0], this.feedbackDuration)
  }

  instanceData.games.forEach(game => {
    let serverStatus = game.status
    if (serverStatus === null) {
      serverStatus = game.enabled ? "enabled" : "disabled"
    }

    const gameData = {
      pin: game.gameId,
      name: "-",
      description: "-",
      infos: JSON.parse(JSON.stringify(GAME_STATS)),
      highScores: [],
      gameReports: [],
      playCountMax: game.playCountMax,
      estimatedDuration: game.estimatedDuration,
      playDisabled: true,
      serverStatus: serverStatus
    }

    setTimesPlayed(gameData)
    setDuration(gameData.infos[4], game.estimatedDuration)

    this.games.push(gameData)
  })

  loadGameDetails.call(this, response.server.data, response.server.serverTime, user.id)
}

async function loadGameDetails(projectData, serverTime, portalUserId) {
  try {
    let previousGameData = null;

    for (let k = 0; k < this.games.length; k++) {
      const gameData = this.games[k]
      const i = projectData.find(g => g.gameId === gameData.pin)
      if (!i)
        continue

      gameData.name = i.name
      nav.setGameName(i.gameId, i.name)

      gameData.description = i.description

      gameData.gameReports = i.gameReports
        .map(endInfo => {
          nav.setReportName(i.gameId + "/" + endInfo.index, utils.getFormattedDate(endInfo.endReportTime))

          return {
            date: utils.getFormattedDate(endInfo.endReportTime),
            report: { gameId:i.gameId, index:endInfo.index },
            points: endInfo.endScore.toFixed(2),
            endStatus: endInfo.endStatus
          }
        })
        .sort((a,b) => b.points - a.points)

      gameData.passedCount = getPassedCount(gameData)
      gameData.status = getGameStatus(gameData, serverTime, previousGameData)

      setPlayButtonEnabled(gameData)
      setTimesPlayed(gameData, i.gameReports.length)
      setPoints(gameData, i.ownScore)
      setStatus(gameData)
      setRank(gameData, i.rank)

      const highScores = i.topScores.map((plrInfo, index) => {
        return {
          rank: (index+1) + ".",
          name: plrInfo.nickName,
          points: plrInfo.score.toFixed(2),
          own: plrInfo.portalUserId === portalUserId
        }
      })

      if (i.rank && i.rank >= 3) {
        highScores.push({
          rank: (i.rank + 1) + ".",
          name: this.portalUser.nickName,
          points: i.ownScore.toFixed(2),
          own: true
        })
      }

      gameData.highScores = highScores
      previousGameData = gameData
    }
  } catch (error) {
    console.log(error)
  }
}

function setTimesPlayed(gameData, timesPlayed = "0") {
  if (gameData.playCountMax !== null && gameData.playCountMax !== undefined) {
    gameData.infos[0].title = timesPlayed + " / " + gameData.playCountMax
  }
  else {
    gameData.infos[0].title = timesPlayed.toString()
  }
}
function setPoints(gameData, points) {
  gameData.infos[2].title = typeof points === 'number' ? points.toFixed(2) : "-"
}
function setRank(gameData, value) {
  gameData.infos[3].title = typeof value === 'number' && value >= 0 ? String(value+1) + "." : "-"
}
function setDuration(obj, duration) {
  obj.title = typeof duration === 'number' ? duration + " min" : "-"
}
function setStatus(gameData) {
  switch(gameData.status) {
    case STATUS_PLAY_PREVIOUS_FIRST:
      gameData.infos[5].title = "Previous"
      gameData.infos[5].critical = true
      break;
    case STATUS_AVAILABLE:
      gameData.infos[5].title = "Available"
      gameData.infos[5].warning = true
      break;
    case STATUS_AVAILABLE_PLAYED:
    case STATUS_NOT_ENABLED_PLAYED:
      gameData.infos[5].title = gameData.passedCount > 0 ? "Passed" : "Not passed"
      gameData.infos[5].success = true
      break;
    case STATUS_AVAILABLE_LIMIT:
      gameData.infos[5].title = "Complete"
      gameData.infos[5].success = true
      break;
    case STATUS_NOT_ENABLED:
      gameData.infos[5].title = "Disabled"
      gameData.infos[5].critical = true
      break;
  }
}
function setPlayButtonEnabled(gameData) {
  switch(gameData.status) {
    case STATUS_AVAILABLE:
    case STATUS_AVAILABLE_PLAYED:
      gameData.playDisabled = false
      break
    default:
      gameData.playDisabled = true
      break
  }
}

const STATUS_AVAILABLE = 0
const STATUS_AVAILABLE_PLAYED = 1
const STATUS_AVAILABLE_LIMIT = 2
const STATUS_NOT_ENABLED = 3
const STATUS_NOT_ENABLED_PLAYED = 4
const STATUS_PLAY_PREVIOUS_FIRST = 5

function getGameStatus(gameData, serverTime, prevGameData) {
  const gameDisabled = gameData.serverStatus === "disabled"
  const playCount = gameData.gameReports.length

  if (gameData.playCountMax && playCount && playCount >= gameData.playCountMax)
    return STATUS_AVAILABLE_LIMIT
  if (gameDisabled && playCount && playCount > 0)
    return STATUS_NOT_ENABLED_PLAYED
  if (gameDisabled)
    return STATUS_NOT_ENABLED
  if (gameData.serverStatus === "play_previous" && prevGameData !== null && prevGameData.passedCount <= 0)
    return STATUS_PLAY_PREVIOUS_FIRST
  if (playCount && playCount > 0)
    return STATUS_AVAILABLE_PLAYED

  return STATUS_AVAILABLE
}

function getPassedCount(gameData) {
  let count = 0;

  for (let i = 0; i < gameData.gameReports.length; i++) {
    const r = gameData.gameReports[i];
    if (!r.endStatus || (!r.endStatus.timeLimit & !r.endStatus.turnLimit || !r.endStatus.riskLimit) ) {
      count++
    }
  }
  return count
}

function getFeedbackUrl(url) {
  if (!url || typeof url !== "string") {
    return "";
  }
  url = url.replace("{user}", this.portalUser.id)
  url = url.replace("{solution}", this.$route.params.id)
  return url
}
function getGameById(id) {
  return this.games.filter(i => i.pin === id)[0]
}


export default {
  name: 'LearningSolution',
  components: {
    InstanceVisitors,
    InstanceTeachingNotes,
    GameReportTable,
    HighScoreTable,
    InstanceTitle,
    InstanceFeedback,
    InstanceGame,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },

  data() {
    return {
      games: [],
      gameReports: [],
      title: "",
      feedbackUrl: null,
      feedbackDuration: null,
      feedbackTitle: "Post-game reflection and feedback",
      feedbackDescription: "Reflection is not part of the grading, but it is compulsory assignment. You need to first complete the game and then provide post game reflection and feedback.",
      feedbackEnabled: false,
      description: "",
      descriptionTags: [],
      details: "",
      portalUser: null,
      surveyInfos: [],
      modalData: {
        visible: false,
        detailedScores: false,
        highScores: [],
        gameReports: []
      },
      doc: window.document,
      teachingNotes: "",
      visitors: null
    }
  },
  computed: {
    visitorsFormatted: function() {
      return this.visitors
        .map(i => {
          return {
            nickName: i.nickName,
            time: utils.getFormattedDate(i.time)
          }
        })
        .sort((a,b) => a.nickName.localeCompare(b.nickName))
    }
  },
  async mounted() {
    try {
      const user = auth.getUser()
      if (!user)
        return;

      const response = await axios.get(utils.getApiUrl('portal-api/pbg-plugin/solutionInstance/' + this.$route.params.id), {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        }
      })

      processLearningSolutionData.call(this, response.data)
    } catch (error) {
      console.log(error)

      if (error.response && error.response.status === 401) {
        this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: 'Login failed or expired, please relogin.'})
        auth.logout()
        this.$router.push({path: '/login', query: {redirect: '/instance/' + this.$route.params.id}})
      }
    }
  },
  methods: {
    showHighScores: async function(gameId, detailed) {
      const response = await axios.get(utils.getApiUrl('portal-api/pbg-plugin/scores/' + gameId), {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        }
      })

      if (response.data.error) {
        this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: response.data.error})
        return
      }

      this.modalData.gameReports = []
      this.modalData.highScores = response.data.data.map((i, index) => {
        const result = {
          rank: (index+1) + ".",
          name: i.nickName,
          points: i.score.toFixed(2),
          own: i.portalUserId === this.portalUser.id
        }
        if (this.portalUser.gameAdmin && detailed) {
          result.firstName = i.firstName
          result.lastName = i.lastName
          result.email = i.email
        }
        return result
      })
      this.modalData.title = getGameById.call(this, gameId).name + " player rankings"
      this.modalData.detailedScores = this.portalUser.gameAdmin && detailed
      this.modalData.visible = true
    },
    showReports: async function(gameId) {
      const gameData = getGameById.call(this, gameId)

      this.modalData.detailedScores = false
      this.modalData.highScores = []
      this.modalData.gameReports = gameData.gameReports
      this.modalData.title = gameData.name + " reports"
      this.modalData.visible = true
    },
    onModalReportClicked: function(reportUrl) {
      this.modalData.visible = false
      setTimeout(() => {
        this.$router.push({path: reportUrl})
      }, 100);
    }
  }
};
</script>
<style>
  body {
    background-color: #12121c;
  }
  .project-game-instance-card{
    background-color: #1e1e2f;
  }
  .own-record {
    font-weight: bold !important;
  }
  .card-title {
    margin-bottom: 0.2rem !important;
  }
  .pbg-modal-wide {
    width: 80% !important;
    max-width: 1024px !important;
  }
</style>
