<template>
  <el-table style="width: 100%" :data="highScores" :cell-class-name="getHighScoreCellClassName">
    <el-table-column min-width="100" label="Rank" prop="rank"></el-table-column>
    <el-table-column min-width="200" label="Nick" prop="name"></el-table-column>
    <el-table-column v-if="admin" min-width="120" style="font-size:smaller" label="First" prop="firstName"></el-table-column>
    <el-table-column v-if="admin" min-width="120" label="Last" prop="lastName"></el-table-column>
    <el-table-column v-if="admin" min-width="200" label="Email" prop="email"></el-table-column>
    <el-table-column min-width="100" label="Points" prop="points"></el-table-column>
  </el-table>
</template>

<script>
import {Table, TableColumn} from "element-ui"

export default {
  name: "HighScoreTable",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    highScores: {
      type: [Array]
    },
    admin: {
      type: [Boolean]
    }
  },
  methods: {
    getHighScoreCellClassName(r) {
      return r.row.own ? 'own-record': ''
    }
  }
}
</script>

<style scoped>
</style>
