<template>
  <div>
    <notifications></notifications>
    <navbar v-if="user && $route.name !== 'game'" :user="user" @logout="logout"></navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "@/components/ProjectGame/Navbar";
import auth from "@/pbg/auth";

export default {
  name: "Layout",
  components: {
    Navbar

  },
  data() {
    return {
      user: null
    }
  },
  mounted() {
    this.user = auth.getUser()

    auth.emitter.on(auth.USER_DATA_CHANGE, () => {
      this.user = auth.getUser()
    })

    // This will trigger also when loaded for the first time
    // document.addEventListener('swUpdateFound', () => {
    //  this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: "Content updated, loading..."})
    // }, { once: true })
  },
  methods: {
    logout: function() {
      this.user = null
    }
  }
}
</script>

<style scoped>

</style>
