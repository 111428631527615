<template>
  <div style="width:100%;height:100%; position: absolute">
    <iframe v-if="gameUrl" :src="gameUrl" width="100%" height="100%" style="width:100%;height:100%">
    </iframe>
  </div>
</template>

<script>
import auth from "@/pbg/auth"
import utils from "@/pbg/utils";

function processWindowMessage(event) {
  if (event.data === "gameComplete") {
    this.$router.push({ path: `/instance/${this.$route.params.id}`})
  }
}

export default {
  name: "GameView",
  mounted() {
    this.iframeWindowEventListener = processWindowMessage.bind(this)
    window.addEventListener("message", this.iframeWindowEventListener, false)

    const user = auth.getUser()
    this.gameUrl = utils.getApiUrl("game/?pin=" + this.$route.params.gameId + "&portal=" + user.id + "&nick=" + encodeURIComponent(user.nickName))
  },
  destroyed() {
    window.removeEventListener('message', this.iframeWindowEventListener)
  },
  data() {
    return {
      gameUrl: null,
      iframeWindowEventListener: null
    }
  }
}
</script>

<style scoped>
</style>
