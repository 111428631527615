<template>
  <card class="project-game-instance-card">
    <div class="row">

      <div class="col-8">
        <h3>{{name}}</h3>
      </div>
      <div class="col-4" style="text-align: right">
          <el-select v-if="gameAdmin" class="select-default" style="margin-right:5px; width:150px" @change="onGameStatusChange($event)" :value="serverStatus">
            <el-option
              v-for="i in gameAdminActions"
              :value="i.value"
              :label="i.label"
              :key="i.id"
            >
            </el-option>
          </el-select>

          <router-link :to="gameRouterPath" :class="'btn btn-info' + (playDisabled ? ' disabled': '')">
            Play
          </router-link>
      </div>

      <div class="col-lg-2" v-for="card in infos" :key="card.id">
        <card class="text-center" :showFooterLine=false>
          <template slot="header">
            <h4 class="card-title pbg-title" v-bind:class="{ 'success-color': card.success, 'warning-color': card.warning, 'critical-color': card.critical }">{{ card.title }}
              <i v-if="card.success" class="tim-icons icon-check-2"></i>
              <i v-if="card.warning || card.critical" class="tim-icons icon-alert-circle-exc"></i>
            </h4>
            <p class="card-category">{{ card.subTitle }}</p>
          </template>
        </card>
      </div>

      <div class="col-12">
        <card>
          <vue-markdown :source="description"></vue-markdown>
        </card>
      </div>

      <div class="col-md-6">
        <card>
          <high-score-table :highScores="highScores"></high-score-table>
          <a v-if="showAllHighScores" href="#" @click.prevent="$emit('showHighScores', $vnode.key)">View all high scores</a>
          <span v-if="gameAdmin">
            (<a href="#" style="font-size: smaller"  @click.prevent="$emit('showHighScores', $vnode.key, true)">detailed</a>)
          </span>
        </card>
      </div>

      <div class="col-md-6">
        <card>
          <game-report-table :gameReports="gameReports" @reportClicked="$router.push({path: arguments[0]})"></game-report-table>
          <a v-if="showAllReports" href="#" @click.prevent="$emit('showReports', $vnode.key)">View all reports</a>
        </card>
      </div>
    </div>
  </card>
</template>

<script>
import GameReportTable from "@/components/ProjectGame/GameReportTable";
import HighScoreTable from "@/components/ProjectGame/HighScoreTable";
import utils from "@/pbg/utils";
import axios from "axios";
import swal from "sweetalert2";
import VueMarkdown from 'vue-markdown'
import {Table, TableColumn} from "element-ui"
import {Option, Select} from "element-ui"

export default {
  name: "InstanceGame",
  components: {
    HighScoreTable,
    GameReportTable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    VueMarkdown
  },
  props: {
    name: {
      type: [String]
    },
    description: {
      type: [String]
    },
    gameRouterPath: {
      type: [String]
    },
    infos: {
      type: [Array]
    },
    highScores: {
      type: [Array]
    },
    gameReports: {
      type: [Array]
    },
    playDisabled: {
      type: [Boolean]
    },
    showAllReports: {
      type: [Boolean]
    },
    showAllHighScores: {
      type: [Boolean]
    },
    gameAdmin: {
      type: [Boolean]
    },
    gameId: {
      type: [String]
    },
    instanceId: {
      type: [String]
    },
    serverStatus: {
      type: [String]
    }
  },
  data() {
    return {
      gameAdminActions: [
        {
          id: 0,
          label: "Enabled",
          value: "enabled"
        },
        {
          id: 1,
          label: "Play previous",
          value: "play_previous"
        },
        {
          id: 2,
          label: "Disabled",
          value: "disabled"
        }
      ]
    }
  },
  methods: {
    onGameStatusChange: async function(newStatus) {
      if (newStatus === this.serverStatus) {
        return
      }

      const result = await swal.fire({
        title: `Are you sure you want to change status to ${newStatus}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
      })
      if (!result.isConfirmed) {
        return
      }

      const gameId = this.gameId;
      const instanceId = this.instanceId;

      const res = await axios.post(utils.getApiUrl("portal-api/pbg-plugin/gameStatus"), {instanceId, gameId, newStatus}, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        }
      })

      if (res.data.error) {
        this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: res.data.error})
        return
      }

      this.$emit('gameStatusUpdated')
    }
  }
}
</script>

<style scoped>
.success-color {
  color: green !important;
}
.warning-color {
  color: orange !important;
}
.critical-color {
  color: red !important;
}
</style>
