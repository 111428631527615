<template>
  <card class="project-game-instance-card">
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <p class="feedback-header"><vue-markdown :source="description"></vue-markdown></p>
          </template>
        </card>
      </div>
    </div>
  </card>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: "InstanceTeachingNotes",
  components: {
    VueMarkdown
  },
  props: {
    description: {
      type: [String]
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.feedback-header {
  margin-bottom: 8.5px;
}
</style>
