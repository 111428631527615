<template>
  <div class="container">
    <div class="row">
      <card>
        <form @submit.stop.prevent="submit">
          <div class="text-center">
            <h2>ProjectBusinessGame Portal</h2>

            <div v-if="visited">
              Visit your previous Learning Solutions
              <div v-for="item in visited" :key="item.id">
                <router-link :to="'instance/'+item.instanceId">
                  {{ item.instanceId }} <small>({{ item.title }})</small>
                </router-link>
              </div>
              <div>&nbsp;</div>
            </div>

            Please enter Learning Solution Identifier
            <base-input
              v-model="instanceId"
              type=""
              label=""
               style="max-width: 200px; margin-left: auto; margin-right: auto">
            </base-input>
            <router-link :to="'instance/'+instanceId" :class="'btn btn-info' + (this.instanceId.length === 0 ? ' disabled': '')">
              Go
            </router-link>

            <div v-if="!user">
              <br>
              Already have an account? <router-link :to="'/login'">Login</router-link>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>

import auth from "@/pbg/auth";
import utils from "@/pbg/utils";
import axios from "axios";

export default {
  name: "Library",
  components: {
  },
  data() {
    return {
      instanceId:"",
      visited: null,
      user: null
    }
  },
  created() {
    this.onUserChangedCallback = this.onUserChanged.bind(this);
    auth.emitter.on(auth.USER_DATA_CHANGE, this.onUserChangedCallback);
  },
  destroyed() {
    auth.emitter.off(auth.USER_DATA_CHANGE, this.onUserChangedCallback);
  },
  async mounted() {
    try {
      this.user = auth.getUser()
      if (!this.user)
        return;

      const response = await axios.get(utils.getApiUrl('portal-api/pbg-plugin/visitedInstances'), {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        }
      })

      if (response.data && response.data.visited && response.data.visited.length > 0) {
        this.visited = response.data.visited;
      }
    } catch (error) {
      console.log(error)

      if (error.response && error.response.status === 401) {
        this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: 'Login failed or expired, please relogin.'})
        auth.logout()
        this.$router.push({path: '/login', query: {redirect: '/'}})
      }
    }
  },
  methods: {
    submit: function() {
      const targetUrl = `/instance/${this.instanceId}`

      if (!auth.loggedIn()) {
        this.$router.push({ path: '/login',  query: {redirect: targetUrl} })
      }
      else {
        this.$router.push({path: targetUrl})
      }
    },
    onUserChanged: function() {
      if (!auth.loggedIn()) {
        this.visited = null;
        this.user = null;
      }
    }
  }
}
</script>

<style scoped>

</style>
