<template>
  <div class="container">
    <card class="project-game-instance-card">
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
        <div class="row">

          <div class="col-md-12">
            <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed, errors }">
              <base-input
                v-model="firstName"
                type=""
                label="First Name"
                :maxlength="32"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </div>

          <div class="col-md-12">
            <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
              <base-input
                v-model="lastName"
                type=""
                label="Last Name"
                :maxlength="32"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </div>

          <div class="col-md-12">
            <ValidationProvider name="nickName" :rules="{required: true, min:3, max: 24, nickIsFreeProfile: true}" v-slot="{ passed, failed, errors }">
              <base-input
                v-model="nickName"
                type=""
                label="Nick name"
                :maxlength="24"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </div>

          <div class="col-md-12">
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="email"
                type="email"
                label="Email address"
                :maxlength="50"
                :error="errors[0]"
                :readonly="true"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </div>

          <div class="col-md-12">
              <base-input
                v-model="username"
                type="hidden"
                label=""
                :readonly="true">
              </base-input>
          </div>

          <div class="col-md-12">
            <ValidationProvider
              name="password"
              rules="confirmed:confirmation|min:4"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="password"
                type="password"
                autocomplete="new-password"
                label="New Password"
                :maxlength="64"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </div>

          <div class="col-md-12">
            <ValidationProvider
              name="confirm"
              vid="confirmation"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                v-model="confirmation"
                type="password"
                autocomplete="off"
                label="Confirm New Password"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>
          </div>

          <div class="col-md-12">
            <base-button native-type="submit" type="primary" class="btn-fill" :disabled="buttonDisabled">
              Save
            </base-button>
          </div>
        </div>
      </form>
      </ValidationObserver>
    </card>
  </div>
</template>

<script>
import auth from "@/pbg/auth";
import utils from "@/pbg/utils";
import axios from "axios";
import { extend } from "vee-validate";
import {confirmed, email, max, min, required} from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("min", min);
extend("max", max);
extend("nickIsFreeProfile", { validate: auth.validateNick });

export default {
  data() {
    return {
      username: "",
      firstName: "",
      lastName: "",
      nickName: "",
      email: "",
      password: "",
      confirmation: "",
      buttonDisabled: false
    };
  },
  mounted() {
    const user = auth.getUser()
    this.username = user.email
    this.firstName = user.firstName
    this.lastName = user.lastName
    this.nickName = user.nickName
    this.email = user.email
  },
  methods: {
    submit() {
      this.buttonDisabled = true;
      auth.update(this.email, this.firstName, this.lastName, this.nickName, this.password, result => {
        this.buttonDisabled = false;
        if (result.errorMessage) {
          this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: result.errorMessage})
        }
        else if (result.updated) {
          this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: "Profile updated."})
          this.$router.go(-1)
        }
      })
    }
  }
};
</script>
<style></style>
