/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
// import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

import router from "./routes/projectGameRouter";

import i18n from './i18n';
import './registerServiceWorker'
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
// Vue.use(RouterPrefetch);

document.addEventListener('swUpdated', (event) => {
  event.detail.waiting.postMessage({ type: 'SKIP_WAITING' });
}, { once: true });

try {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (window.pbgRefreshing) {
      return;
    }
    window.pbgRefreshing = true;
    window.location.reload();
  })
}
catch (err) {
}


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n
});
