<template>
  <div class="container">
    <notifications></notifications>

    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-white">
            <template slot="header">
              <img src="img/card-primary.png" alt="" />
              <h1 class="card-title">Forgot password</h1>
            </template>

            <div>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
              <base-input
                required
                v-model="email"
                type="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-email-85"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
             </ValidationProvider>

            </div>

            <div slot="footer">
              <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
                Send recovery email
              </base-button>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import auth from "./../../pbg/auth";

extend("email", email);
extend("min", min);
extend("required", required);

export default {
  data() {
    return {
      email: ""
    };
  },
  methods: {
    submit() {
      auth.forgotPassword(this.email, result => {
        if (result.errorMessage) {
          this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: result.errorMessage})
        } else {
          this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: "Email sent"})
        }
      })
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
