
import axios from "axios"
import moment from 'moment'

export default {
  getFormattedDate(date) {
    return moment(date).format('D.M.YYYY HH:mm')
  },

  getFormattedTimeTo(date, current) {
    return moment(date).from(current, true)
  },

  getApiUrl(target) {
    return "/" + target
  },

  setDarkMode(darkEnabled) {
    let docClasses = document.body.classList;
    if (darkEnabled) {
      docClasses.remove('white-content');
    } else if (!docClasses.contains('white-content')) {
      docClasses.add('white-content');
    }
    localStorage.setItem('darkMode', darkEnabled ? "1" : "0")
  },

  getDarkMode() {
    const value = localStorage.getItem("darkMode");
    if (value === null) {
      return true;
    }
    return value === "1";
  },

  async getGdprUrl() {
    try {
      const response = await axios.get(this.getApiUrl('portal-api/pbg-settings/1'))
      const urlToFile = response.data.gdpr.url
      return this.getApiUrl('portal-api/' + urlToFile)
    }
    catch(err) {
      console.log(err)
      return "";
    }
  }
}
