<template>
  <div class="container">
    <div class="row">
      <card>
        <h1><i class="tim-icons icon-alert-circle-exc"></i> 404</h1>
        <p>The page you requested could not be found.</p>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
