<template>
  <div>
    <div style="width:100%;height:calc(100% - 54px); position: absolute">
      <iframe id="gameReportFrame" v-if="gameReportUrl" :src="gameReportUrl" width="100%" height="100%" style="width:100%;height:100%">
      </iframe>
    </div>
    <div class="container">
      <card v-if="reportError">
        <h1><i class="tim-icons icon-alert-circle-exc"></i> No report</h1>
        <p>The report you requested could not be found.</p>
      </card>
    </div>
  </div>
</template>

<script>

import utils from "@/pbg/utils";
import axios from "axios";

function processWindowMessage(event) {
  if (event.data === "readyForGameReport") {
    document.getElementById("gameReportFrame").contentWindow.postMessage({gameReport: this.gameReport}, "*")
  }
}

export default {
  name: "GameReport",
  components: {
  },
  data() {
    return {
      gameReportUrl: "",
      gameReport: "",
      reportError: false
    }
  },
  async mounted() {
    this.iframeWindowEventListener = processWindowMessage.bind(this)
    window.addEventListener("message", this.iframeWindowEventListener, false)

    const params = this.$route.params.gameId + "/" + this.$route.params.reportIndex;
    const response = await axios.get(utils.getApiUrl('portal-api/pbg-plugin/game-report/' + params), {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      }
    })

    if (response.data.data) {
      this.gameReport = response.data.data
      this.gameReportUrl = utils.getApiUrl("game/?pin=" + this.$route.params.gameId + "&view=endReport")
    }
    else {
      this.reportError = true
    }
  },
  destroyed() {
    window.removeEventListener('message', this.iframeWindowEventListener)
  }
}

</script>

<style scoped>

</style>
