<template>
  <el-table style="width: 100%" :data="gameReports">
    <el-table-column min-width="300" label="Game report">
      <div class="td-name" slot-scope="{ row }">
        <router-link :to="getReportLink(row.report)">
          <a @click.stop.prevent="reportClicked(row.report)">{{row.date}}</a>
        </router-link>
      </div>
    </el-table-column>
    <el-table-column min-width="100" label="Points" prop="points">
    </el-table-column>
  </el-table>
</template>

<script>
import {Table, TableColumn} from "element-ui"

export default {
  name: "GameReportTable",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    gameReports: {
      type: [Array]
    }
  },
  methods: {
    getReportLink: function(report) {
      return `/instance/${this.$route.params.id}/report/${report.gameId}/${report.index}`
    },
    reportClicked: function(r) {
      this.$emit('reportClicked', this.getReportLink(r));
    }
  }
}
</script>

<style scoped>
</style>
