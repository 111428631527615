
import EventEmitter from "eventemitter3";

const nav = {};

nav.emitter = new EventEmitter();
nav.instanceNames = {};
nav.gameNames = {};
nav.reportNames = {};

nav.setInstanceName = function(instanceId, newInstanceName) {
  this.instanceNames[instanceId] = newInstanceName;
  this.emitter.emit(this.INSTANCE_NAME_CHANGE);
}

nav.setGameName = function(gameId, gameName) {
  this.gameNames[gameId] = gameName;
  this.emitter.emit(this.INSTANCE_NAME_CHANGE);
}

nav.setReportName = function(reportId, reportName) {
  this.reportNames[reportId] = reportName;
  this.emitter.emit(this.INSTANCE_NAME_CHANGE);
}

nav.INSTANCE_NAME_CHANGE = "INSTANCE_NAME_CHANGE";


export default nav;
