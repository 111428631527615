<template>
  <div class="container">
    <notifications></notifications>

  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <card v-if="!showCreationForm">
      <div slot="header"><h4 class="card-title">Registration Complete</h4></div>
      <div>
        Account activation email has been sent to {{email}}.
      </div>
    </card>
    <card v-if="showCreationForm" footer-classes="text-right">
      <div slot="header"><h4 class="card-title">Register Form</h4></div>
      <div>
        <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed, errors }">
          <base-input
            required
            v-model="firstName"
            type=""
            label="First Name"
            :maxlength="32"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
          <base-input
            required
            v-model="lastName"
            type=""
            label="Last Name"
            :maxlength="32"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider name="nick" :rules="{required: true, min:3, max: 24, nickIsFree: true}" v-slot="{ passed, failed, errors }">
          <base-input
            required
            v-model="nick"
            type=""
            label="Nick name"
            :maxlength="24"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider
          name="email"
          rules="required|email"
          v-slot="{ passed, failed, errors }"
        >
        <base-input
          required
          v-model="email"
          type="email"
          label="Email address"
          :maxlength="50"
          :error="errors[0]"
          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
        </base-input>
       </ValidationProvider>

       <ValidationProvider
         name="password"
         rules="required|confirmed:confirmation|min:4"
         v-slot="{ passed, failed, errors }"
       >
       <base-input
         required
         v-model="password"
         type="password"
         autocomplete="new-password"
         label="Password"
         :maxlength="64"
         :error="errors[0]"
         :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
       </base-input>
      </ValidationProvider>

      <ValidationProvider
        name="confirm"
        vid="confirmation"
        rules="required"
        v-slot="{ passed, failed, errors }"
      >
      <base-input
        required
        v-model="confirmation"
        type="password"
        label="Confirm Password"
        :error="errors[0]"
        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
      </base-input>
     </ValidationProvider>

        <div class="category form-category">* Required fields</div>
      </div>
      <template slot="footer" class="text-right">
        <base-checkbox
          v-model="subscribe"
          class="pull-left"
          name="subscribe"
        >
          Accept <a :href="gdprUrl" target="_blank">terms and conditions</a>
        </base-checkbox>
        <base-button
          :disabled="!subscribe"
          native-type="submit"
          type="primary"
          >Register</base-button
        >
      </template>
    </card>
  </form>
  </ValidationObserver>
  </div>
</template>
<script>
import auth from "@/pbg/auth";
import utils from "@/pbg/utils";
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed, min, max } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("min", min);
extend("max", max);
extend("nickIsFree", { validate: auth.validateNick });

export default {
  components: {
    BaseCheckbox
  },
  data() {
    return {
      username: "",
      firstName: "",
      lastName: "",
      nick: "",
      email: "",
      password: "",
      confirmation: "",
      subscribe: false,
      showCreationForm: true,
      gdprUrl: ""
    };
  },
  methods: {
    submit() {
      auth.register(this.firstName, this.lastName, this.nick, this.email, this.password, result => {
        if (result.errorMessage) {
          this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: result.errorMessage})
        } else if (result.loggedIn){
          this.$router.replace(this.$route.query.redirect || '/')
        }
        else {
          this.showCreationForm = false
        }
      })
    }
  },
  async mounted() {
    this.gdprUrl = await utils.getGdprUrl()
  }
};
</script>
<style></style>
