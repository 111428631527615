<template>
  <card class="project-game-instance-card">
    <div class="row">
      <div class="col-8">
        <h3>{{title}}</h3>
      </div>
      <div class="col-4" style="text-align: right">
        <base-button style="margin-right:5px" v-if="gameAdmin" :type="feedbackEnabled ? 'danger' : 'success'" @click="onSurveyEnableClick">{{ feedbackEnabled ? "Disable" : "Enable" }}</base-button>

        <base-button type="info" @click="onSurveyClicked(feedbackUrl)" :disabled="!feedbackEnabled">Take survey</base-button>
      </div>

      <div class="col-lg-10">
        <card>
          <template slot="header">
            <p class="feedback-header"><vue-markdown :source="description"></vue-markdown></p>
          </template>
        </card>
      </div>

      <div class="col-lg-2" v-for="card in infos" :key="card.id">
        <card
          :title="card.title"
          :sub-title="card.subTitle"
          class="text-center"
          :showFooterLine=false
        >
        </card>
      </div>
    </div>
  </card>
</template>

<script>
import utils from "@/pbg/utils";
import axios from "axios";
import swal from "sweetalert2";
import VueMarkdown from 'vue-markdown'

export default {
  name: "InstanceFeedback",
  components: {
    VueMarkdown
  },
  props: {
    title: {
      type: [String]
    },
    description: {
      type: [String]
    },
    infos: {
      type: [Array]
    },
    feedbackUrl: {
      type: [String]
    },
    gameAdmin: {
      type: [Boolean]
    },
    feedbackEnabled: {
      type: [Boolean]
    },
    instanceId: {
      type: [String]
    }
  },
  methods: {
    onSurveyClicked: feedbackUrl => {
      window.open(feedbackUrl)
    },
    onSurveyEnableClick: async function() {
      const result = await swal.fire({
        title: this.feedbackEnabled ? `Are you sure you want to disable survey?` : `Are you sure you want to enable survey?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
      })
      if (!result.isConfirmed) {
        return
      }

      const feedbackEnabled = !this.feedbackEnabled;
      const instanceId = this.instanceId;

      const res = await axios.post(utils.getApiUrl("portal-api/pbg-plugin/gameStatus"), {instanceId, feedbackEnabled}, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        }
      })

      if (res.data.error) {
        this.$notify({verticalAlign: 'top', horizontalAlign: 'center', message: res.data.error})
        return
      }

      this.$emit('feedbackStatusUpdated')
    }
  }
}
</script>

<style scoped>
.feedback-header {
  margin-bottom: 8.5px;
}
</style>
